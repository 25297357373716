 body {
      background-color: #f9eaea;
      font-family: 'Poppins';
      margin: 0;
      padding: 20px;
    }

.container {
      max-width: 800px;
      margin: 0 auto;
      border-radius: 20px;
      background: linear-gradient(to bottom, #f2f2f2, #ffffff);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 40px;
    }

.file-list {
      list-style: none;
      padding: 0;
    }

.file-list li {
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

.file-list li:hover {
      background-color: #f1f1f1;
    }

.file-icon {
      font-size: 24px;
      margin-right: 10px;
    }

.file-name {
      font-weight: bold;
    }
	
.mdi-file {
		color: pink;
		padding-right: 20px;
		padding-left: 15px;
	}

	
a {
		color: #ce93d8;
		font-weight: bold;
		text-decoration: none;
	}


.button {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button {
    padding: 0.25em 0.75em;
  }
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

